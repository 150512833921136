/**
 * @type {import('vue-router').RouteConfig[]}
 */
export default [
    {
        name: 'seller.dashboard',
        path: '/sellers/:userId/dashboard',
        component: () => import('./SellerDashboard.vue'),
        redirect: { name: 'seller.dashboard.proposals' },
        children: [
            {
                name: 'seller.dashboard.proposals',
                path: '/sellers/:userId/dashboard/proposals',
                component: () => import('./views/SellerProposalList.vue'),
            },
            {
                name: 'seller.dashboard.proposal-single',
                path: '/sellers/:userId/dashboard/proposals/:proposalId',
                component: () => import('./views/SellerProposalSingle.vue'),
            },
            {
                name: 'seller.dashboard.proposal-version-single',
                path: '/sellers/:userId/dashboard/proposals/:proposalId/versions/:versionId',
                component: () => import('@/views/ProposalVersionSingle/ProposalVersionSingle.vue'),
            },
        ]
    }
]