import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

import ptBR from '../lang/pt-BR'
import enUS from '../lang/en-US'
import { appConfig } from './appConfig'

const messages = {
    'pt-BR': ptBR,
    'en-US': enUS
}

export const i18n = new VueI18n({
    locale: appConfig.language,
    fallbackLocale: 'pt-BR',
    messages
})
