export function calculateProductPrice(options) {

    const { minimum_markup, fob_cost, dollar, nationalized_cost_multiplier } = options

    const fob_to_brl = fob_cost * dollar

    const nationalized_cost = fob_to_brl * nationalized_cost_multiplier
    const final_cost = nationalized_cost * (1 + minimum_markup)

    return {
        minimum_markup,
        fob_to_brl,
        nationalized_cost,
        final_cost,
    }
}