<template>
    <v-autocomplete
        v-model="model"
        autocomplete="nope"
        :label="$t('product')"
        outlined
        hide-details="auto"
        return-object
        item-text="name"
        item-value="_id"
        multiple
        :items="products"
        :filter="filterProducts"
    >
        <template #selection="{ item, index }">
            <v-chip v-if="index === 0">
                <span>{{ truncate(item.name, { length: 20 }) }}</span>
            </v-chip>

            <span
                v-if="index === 1"
                class="grey--text text-caption"
            >
                (+{{ model.length - 1 }})
            </span>
        </template>

        <template #item="{ item }">
            <v-list-item-avatar>
                <v-img :src="item.image" />
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title v-html="item.name" />
                <v-list-item-subtitle v-html="item.code" />
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
import truncate from 'lodash/truncate'

export default {
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        products: {
            type: Array,
            default: () => [],
        },        
    },
    data(){
        return {
            truncate
        }
    },
    computed: {
        model: {
            get(){
                return this.value;
            },
            set(value){
                this.$emit('input', value);
            },
        },
        filterProducts(item, queryText){
            const hasCode = item.name?.toLowerCase().includes(queryText.toLowerCase())
            const hasName = item.code?.toLowerCase().includes(queryText.toLowerCase())

            return hasCode || hasName
        },
    },
}
</script>