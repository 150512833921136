import Api from "@/lib/Api";

export default {
    namespaced: true,
    state: {
        data: [],
        oldData: [
            {
                id: "Fenrir",
                index: "1",
                name: "Fenrir"
            },
            {
                id: "Asgard",
                index: "2",
                name: "Asgard"
            },
            {
                id: "Ragnar",
                index: "3",
                name: "Ragnar"
            },
            {
                id: "Aesir",
                index: "4",
                name: "Aesir"
            },
            {
                id: "Týr",
                index: "5",
                name: "Týr"
            },
            {
                id: "Thor",
                index: "6",
                name: "Thor"
            },
            {
                id: "Bikes & Spinning",
                index: "7",
                name: "Bikes & Spinning"
            },
            {
                id: "Esteiras",
                index: "8",
                name: "Esteiras"
            },
            {
                id: "Elípticos e ATM's",
                index: "9",
                name: "Elípticos e ATM's"
            },
            {
                id: "Cardio Diversos",
                index: "10",
                name: "Cardio Diversos"
            },
            {
                id: "Peso Livre",
                index: "11",
                name: "Peso Livre"
            },
            {
                id: "Barras e Acessórios",
                index: "12",
                name: "Barras e Acessórios"
            },
            {
                id: "Bifrost",
                index: "13",
                name: "Bifrost"
            },
            {
                id: "Baldur",
                index: "14",
                name: "Baldur"
            },
            {
                id: "Skadi",
                index: "15",
                name: "Skadi"
            },
            {
                id: "Liv",
                index: "16",
                name: "Liv"
            },
            {
                id: "LX",
                index: "17",
                name: "LX"
            },
            {
                id: "Loki",
                index: "18",
                name: "Loki"
            },
            {
                id: "Racks e Suportes",
                index: "19",
                name: "Racks e Suportes"
            },
            {
                id: "JW",
                index: "20",
                name: "JW"
            },
            {
                id: "Neo",
                index: "21",
                name: "Neo"
            },
            {
                id: "Nordic",
                index: "22",
                name: "Nordic"
            },
            {
                id: "Rauk",
                index: "23",
                name: "Rauk"
            },
            /*
            {
                id: "Odin",
                name: "Odin"
            },
            {
                id: "Cable Motion",
                name: "Cable Motion"
            },
            */
        ]
    },
    mutations: {
        setData(state, payload) {
            state.data = payload;
        }
    },
    actions: {
        async load({ commit }) {            
            const response = await Api.getSubcategories();

            commit("setData", response.error ? [] : response.message);

            return response;
        }
    }
}