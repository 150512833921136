<template>
    <div>
        <v-col cols="12">
            <v-row>
                <v-col
                    v-for="(image, index) in model"
                    :key="index"
                    cols="4"
                >
                    <v-card outlined>
                        <app-img
                            :src="image"
                            width="100%"
                            height="80"
                            contain
                        />

                        <v-card-actions>
                            <v-btn
                                color="error"
                                small
                                block
                                @click="remove(index)"
                            >
                                {{ $t('remove') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col cols="12">
                    <v-card
                        outlined
                        @click="add"
                    >
                        <v-card-text class="text-center">
                            {{ $t('addEntity', [$t('images')]) }}
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            default: () => [],
        }
    },
    computed: {
        model: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    methods: {
        remove(index) {
            this.model.splice(index, 1);
        },
        async add() {
            const files = await this.$utils.pickFile({
                accept: 'image/*',
                multiple: true,
            })

            if (!files) return

            files.forEach(file => {
                this.model.push({
                    src: URL.createObjectURL(file),
                    file,
                })
            })
        },
        async upload(){
            for await (const image of this.model) {
                if (!image.file) continue;

                const { error, message }= await this.$api.uploadImage(image.file);

                if (error) {
                    return 
                }

                delete image.file;

                image.src = message;
            }
        }
    }
}
</script>
