<template>
    <v-chip
        v-if="current"
        v-bind="$attrs"
        :color="current.color"
        :dark="current.dark"
    >
        {{ current.text }}
    </v-chip>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: null
        },
        items: {
            type: Array,
            default: () => []
        },
    },
    computed: {
        current(){
            return this.items.find(item => item.value === this.value)
        }
    }
}

</script>

