<template>
    <app-file-picker :file.sync="file" accept="image/*" class="w-full">
        <template #default="{ pick }">
            <v-card :min-height="minHeight" class="d-flex flex-column justify-space-between " flat>
                <div class="grow w-full min-h-inherit" v-if="showThumbnail">
                    <v-img v-if="preview" :src="preview" :width="width || '100%'" :height="height || 'auto'" contain style="border: 1px solid #f0f0f0" />

                    <div v-else @click="pick" class="w-full h-full min-h-inherit grey d-flex justify-center cursor-pointer">
                        <v-icon color="white" size="100">
                            mdi-image
                        </v-icon>
                    </div>
                </div>

                <div class="d-flex justify-space-between pt-2">
                    <template v-if="!image">
                        <v-btn color="success" block @click.stop="pick">
                            <v-icon class="mr-2">
                                mdi-image
                            </v-icon>
                            {{ $t('pickImage') }}
                        </v-btn>
                    </template>

                    <template v-else>
                        <v-btn color="error" style="width: calc(50% - 4px);" @click.stop="image = null">
                            <v-icon>mdi-close</v-icon>
                            {{ $t('remove') }}
                        </v-btn>

                        <v-btn @click.stop="pick" style="width: calc(50% - 4px);" color="primary">
                            <v-icon>mdi-image</v-icon>
                            {{ $t('change') }}
                        </v-btn>
                    </template>
                </div>
            </v-card>
        </template>
    </app-file-picker>
</template>

<script>
export default {
    props: {
        value: {
            type: [File, String],
            default: null,
        },
        minHeight: {
            type: [Number, String],
            default: 100,
        },
        width: {
            type: Number
        },
        height: {
            type: Number
        },
        showThumbnail: {
            type: Boolean,
            default: true
        },
    },
    data: () => ({
        preview: null,
    }),
    computed: {
        file: {
            get() {
                if (this.value instanceof File) {
                    return this.value
                }

                return null
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        image: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
    },
    methods: {
        setPreview() {
            if (this.image instanceof File) {
                this.preview = URL.createObjectURL(this.image)
                return
            }

            if (typeof this.image === 'string') {
                this.preview = this.image
                return
            }

            this.preview = null
        }
    },
    watch: {
        image: {
            immediate: true,
            handler: 'setPreview'
        },
    },
}
</script>