<template>
    <app-dialog v-model="dialog" max-width="600">
        <v-card>
            <v-card-title>
                Informações financeiras
                <v-btn v-if="!icmsField" small outlined class="ml-4" @click="icmsField = true">
                    <v-icon size="20" class="mr-2">mdi-pencil-outline
                    </v-icon>
                    ICMS
                </v-btn>
                <v-text-field v-if="icmsField" label="ICMS" suffix="%" dense type="number" class="mx-2 ml-4 my-2 control-width" hide-details="auto" outlined v-model="icms_tax_rate"></v-text-field>
            </v-card-title>

            <v-card-text>
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    <b>Item</b>
                                </th>
                                <th class="text-left">
                                    <b>Valor</b>
                                </th>
                                <th class="text-left">
                                    <b>%</b>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><b>Preço de venda</b></td>
                                <td class="success--text"><b>+ {{ $currency.main(proposal_version.final_price) }}</b></td>
                                <td class="success--text"><b>100%</b></td>
                            </tr>

                            <tr>
                                <td><b>Custo nacionalizado</b></td>
                                <td class="warning--text"><b>- {{ $currency.main(nationalized_cost) }}</b></td>
                                <td class="warning--text"><b>-{{ $number.toFloat(100 * nationalized_cost / proposal_version.final_price) }} %</b></td>
                            </tr>
                            <tr>
                                <td>Comissão de venda</td>
                                <td class="error--text">- {{ $currency.main(comission) }}</td>
                                <td class="error--text">- {{ $number.toFloat(100 * comission / proposal_version.final_price) }} %</td>
                            </tr>
                            <tr>
                                <td>PIS</td>
                                <td class="error--text">- {{ $currency.main(pis) }}</td>
                                <td class="error--text">- {{ $number.toFloat(100 * pis / proposal_version.final_price) }} %</td>
                            </tr>
                            <tr>
                                <td>COFINS</td>
                                <td class="error--text">- {{ $currency.main(cofins) }}</td>
                                <td class="error--text">- {{ $number.toFloat(100 * cofins / proposal_version.final_price) }} %</td>
                            </tr>
                            <tr>
                                <td>IPI</td>
                                <td class="error--text">- {{ $currency.main(ipi) }}</td>
                                <td class="error--text">- {{ $number.toFloat(100 * ipi / proposal_version.final_price) }} %</td>
                            </tr>
                            <tr>
                                <td>ICMS</td>
                                <td class="error--text">- {{ $currency.main(icms) }}</td>
                                <td class="error--text">- {{ $number.toFloat(100 * icms / proposal_version.final_price) }} %</td>
                            </tr>
                            <tr class="error">
                                <td><b>Custos diretos de venda</b></td>
                                <td><b>- {{ $currency.main(direct_sales_cost) }}</b></td>
                                <td><b>- {{ $number.toFloat(100 * direct_sales_cost / proposal_version.final_price) }} %</b></td>
                            </tr>

                            <tr>
                                <td>Créditos PIS</td>
                                <td class="success--text">+ {{ $currency.main((pis_tax_rate / 100) * nationalized_cost) }}</td>
                                <td class="success--text">+ {{ $number.toFloat(pis_tax_rate) }} %</td>
                            </tr>
                            <tr>
                                <td>Créditos COFINS</td>
                                <td class="success--text">+ {{ $currency.main((cofins_tax_rate / 100) * nationalized_cost) }}</td>
                                <td class="success--text">+ {{ $number.toFloat(cofins_tax_rate) }} %</td>
                            </tr>
                            <tr>
                                <td>Créditos IPI</td>
                                <td class="success--text">+ {{ $currency.main((ipi_tax_rate / 100) * nationalized_cost) }}</td>
                                <td class="success--text">+ {{ $number.toFloat(ipi_tax_rate) }} %</td>
                            </tr>
                            <tr>
                                <td>Créditos ICMS</td>
                                <td class="success--text">+ {{ $currency.main((icms_tax_rate / 100) * nationalized_cost) }}</td>
                                <td class="success--text">+ {{ $number.toFloat(icms_tax_rate) }} %</td>
                            </tr>
                            <tr class="success">
                                <td><b>Crédito de Impostos na Importação</b></td>
                                <td><b>+ {{ $currency.main(credits) }}</b></td>
                                <td><b>+ {{ $number.toFloat(100 * credits / proposal_version.final_price) }} %</b></td>
                            </tr>
                            <br>
                            <tr>
                                <td><b>Margem de contribuição</b></td>
                                <td class="success--text"><b>+ {{ $currency.main(proposal_version.final_price - nationalized_cost - direct_sales_cost + credits) }}</b></td>
                                <td class="success--text"><b>+ {{ $number.toFloat(100 * (proposal_version.final_price - nationalized_cost - direct_sales_cost + credits) / proposal_version.final_price) }} %</b></td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </app-dialog>
</template>

<script>
export default {
    name: "ProposalFinancialInfo",
    data() {
        return {
            icmsField: false,
            comission_tax_rate: 0.5,
            pis_tax_rate: 1.65,
            cofins_tax_rate: 7.6,
            ipi_tax_rate: 13,
            icms_tax_rate: 18
        }
    },
    props: {
        proposal_version: {
            type: Object,
            default: {}
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        nationalized_cost() {
            let value = 0;
            if (!this.proposal_version.items) return value;

            this.proposal_version.items.forEach(i => {
                value += i.nationalized_cost * i.quantity
            });

            return value;
        },
        comission() {
            let value = 0;
            if (this.proposal_version.final_price) value = this.proposal_version.final_price * this.comission_tax_rate / 100;
            return value;
        },
        pis() {
            let value = 0;
            if (this.proposal_version.final_price) value = this.proposal_version.final_price * this.pis_tax_rate / 100;
            return value;
        },
        cofins() {
            let value = 0;
            if (this.proposal_version.final_price) value = this.proposal_version.final_price * this.cofins_tax_rate / 100;
            return value;
        },
        ipi() {
            let value = 0;
            if (this.proposal_version.final_price) value = this.proposal_version.final_price * this.ipi_tax_rate / 100;
            return value;
        },
        icms() {
            let value = 0;
            if (this.proposal_version.final_price) value = this.proposal_version.final_price * this.icms_tax_rate / 100;
            return value;
        },
        direct_sales_cost() {
            let value = 0;
            value = this.comission + this.pis + this.cofins + this.ipi + this.icms;
            return value;
        },
        credits() {
            let value = 0;
            if (this.nationalized_cost) value = ((this.pis_tax_rate + this.cofins_tax_rate + this.ipi_tax_rate + this.icms_tax_rate) / 100) * this.nationalized_cost;
            return value;
        },
    },
    async mounted() {

    },
    methods: {

    },

}
</script>

<style>
.control-width {
    max-width: 110px;
}
</style>