<template>
    <v-app>
        <app-confirm-dialog />
        
        <dashboard v-if="$route.meta.layout === 'dashboard'" />

        <public v-else-if="$route.meta.layout === 'public'" />

        <public v-else-if="$route.meta.layout === 'print'" />
        
        <router-view v-else />
    </v-app>
</template>

<script>
export default {
    name: "App",
    components: {
        // layouts
        Dashboard: () => import("@/layouts/Dashboard.vue"),
        Public: () => import("@/layouts/Public.vue"),

        // components
        AppConfirmDialog: () => import("@/components/AppConfirmDialog.vue"),
    },
    computed: {
        appConfig() {
            return this.$appConfig;
        },
    },
    methods: {
        async load(){
            // load store data
            const promises = [
                this.$store.dispatch('products/load'),
                this.$store.dispatch('subcategories/load'),
                this.$store.dispatch('categories/load'),
            ];

            await Promise.all(promises);
        },
    },
    mounted(){
        this.$store.dispatch('loadCurrentUser')

        this.load()
    },
    created() {
        window.document.title = this.$appConfig.title;
    }
};
</script>
