import Api from "@/lib/Api";
import { calculateProductPrice } from "@/lib/Calculations";

export default {
    namespaced: true,
    state: {
        data: []
    },
    mutations: {
        setData(state, data) {
            state.data = data;
        }
    },
    actions: {
        async load({ commit }){
            const response = await Api.getProducts()

            if (response.error) return

            commit('setData', response.message)
        }
    },
    getters: {
        withPrice(state, getters, rootState, rootGetters){
            const dollar = rootState.dollar.value
            const nationalized_cost_multiplier = rootGetters['settings/getByName']('nationalized_cost_multiplier', null)

            if (!nationalized_cost_multiplier) {
                console.error('nationalizedCostMultiplier needs to be loaded to calculate price')
                return state.data
            }

            if (!dollar) {
                console.error('Dollar needs to be loaded to calculate price')
                return state.data
            }

            return state.data.map(p => {
                const result = calculateProductPrice({
                    minimum_markup: (p.subcategory?.minimum_markup || 0) / 100,
                    fob_cost: p.FOB_cost,
                    dollar,
                    nationalized_cost_multiplier
                })

                return {
                    ...p,
                    ...result
                }
            })
        }
    }
}