import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ptBR from 'vuetify/lib/locale/pt';
import enUS from 'vuetify/lib/locale/en';
import { appConfig } from './appConfig';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        current: appConfig.language,
        locales: {
            'pt-BR': ptBR,
            'en-US': enUS
        }
    },
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: appConfig.colors.primary,
                galaxy: appConfig.colors.galaxy,
                stardust: appConfig.colors.stardust,
                moon: appConfig.colors.moon,
                error: appConfig.colors.error,
                mercury: appConfig.colors.mercury
            }
        }
    }
});
