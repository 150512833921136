<template>
    <div class="py-4 text-caption d-flex align-center">
        <div class="d-flex flex-column">
            <slot
                name="empty"
                v-if="!itemAttrs.length"
            >
                {{ $t('noEntity', [$tc('customization', 2)]) }}
            </slot>
    

            <div                
                class="d-flex space-x-2"
                v-for="a in itemAttrs.filter(a => a.value)"
                :key="a.name"
            >
                <div>{{ a.text }}:</div>
                <div>{{ a.value }}</div>
            </div>
        </div>

        <v-btn
            text
            small
            icon
            class="ml-auto"
            v-if="!readonly"
            @click="dialog = true"
        >
            <v-icon small>
                mdi-pencil
            </v-icon>
        </v-btn>

        <app-dialog
            v-model="dialog"
            max-width="500"
        >
            <v-card>
                <v-card-title>
                    {{ $tc('customization', 2) }}
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col
                            cols="12"
                            v-if="!categoryAttrs.length"
                        >
                            {{ $t('noCustomizationAvailable') }}
                        </v-col>
                        
                        <v-col
                            cols="12"
                            v-for="a in categoryAttrs"
                            :key="a.name"
                        >
                            <v-select
                                v-model="payload[a.name]"
                                outlined
                                :label="$t(a.name)"
                                :items="a.options"
                                item-text="text"
                                item-value="value"
                                clearable
                                hide-details
                            />
                        </v-col>

                        <v-col
                            v-if="categoryAttrs.length"
                            class="space-y-2"
                        >
                            <v-btn
                                @click="applyToAllOfSameCategory"
                                color="primary"
                                block
                            >
                                {{ $t('applyToAllOfSameCategory') }}
                            </v-btn>
                            <v-btn
                                @click="apply"
                                color="primary"
                                block
                            >
                                {{ $t('apply') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </app-dialog>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        language: {
            type: String,
            default: null,
        }
    },
    data: () => ({
        dialog: false,
        payload: {}
    }),
    computed: {
        categories() {
            return this.$store.state.categories.data
        },
        category(){
            return this.categories.find(c => c._id === this.item.category_id)
        },
        categoryAttrs() {
            return this.category?.attrs || []
        },
        itemAttrs() {
            const attrs = this.item.attrs || []

            return attrs.map(a => {
                const categoryAttr = this.categoryAttrs.find(ca => ca.name === a.name)
                const option = categoryAttr?.options.find(o => o.value === a.value)

                if (!categoryAttr || !option) {
                    return {
                        name: a.name,
                        text: a.name,
                        value: a.value
                    }
                }

                let text = categoryAttr.text
                let value = option.text

                if (this.language === 'en') {
                    text = categoryAttr.text_en
                    value = option.text_en
                }

                if (this.language === 'zh') {
                    text = categoryAttr.text_zh
                    value = option.text_zh
                }

                return {
                    name: a.name,
                    text: text || a.name,
                    value: value || a.value
                }
            })
        },
    },
    methods: {
        setPayload(){
            this.payload = {}

            this.categoryAttrs.forEach(ca => {
                const itemAttr = this.itemAttrs.find(i => i.name === ca.name)

                this.payload[ca.name] = itemAttr?.value || ''
            })
        },
        getAttrText(name){
            const attr = this.categoryAttrs.find(ca => ca.name === name)

            if (!attr) return name

            if (this.language === 'en') return attr.text_en

            if (this.language === 'zh') return attr.text_zh

            return attr.text
        },
        getAttrOptionValueText(name, value){
            const attr = this.categoryAttrs.find(ca => ca.name === name)
            const option = attr?.options.find(o => o.value === value)

            if (!attr || !option) return value

            if (this.language === 'en') return option.text_en

            if (this.language === 'zh') return option.text_zh

            return option?.text
        },
        apply(){
            const attrs = this.categoryAttrs.map(ca => {
                return {
                    name: ca.name,
                    value: this.payload[ca.name],
                }
            })

            this.$emit('apply', attrs)

            this.dialog = false
        },
        applyToAllOfSameCategory(){
            const attrs = this.categoryAttrs.map(ca => {
                return {
                    name: ca.name,
                    value: this.payload[ca.name],
                }
            })

            this.$emit('apply-all', attrs)

            this.dialog = false
        },
    },
    watch: {
        dialog: 'setPayload'
    }
}
</script>
