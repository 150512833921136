import Api from "@/lib/Api";

export default {
    namespaced: true,
    state: {
        data: [],
    },
    mutations: {
        setData(state, data) {
            state.data = data;
        },
    },
    actions: {
        async load({ commit }) {
            const response = await Api.getSuppliers();

            commit("setData", response.error ? [] : response.message);
            
            return response;
        }
    }
}