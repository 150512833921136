export default {
    proposalGenerator: "Proposal Generator",
    myProfile: "My Profile",
    proposal: "Proposal | Proposals",
    proposalName: "Proposal name",
    client: "Customer | Customers",
    product: "Product",
    products: "Products",
    user: "User | Users",
    setting: "Setting | Settings",
    logout: "Logout",
    markup: "Markup | Markups",
    noEntity: "No {0}",
    newEntity: "New {0}",
    entityList: "{0} List",
    entityDetails: "{0} Details",
    entitySaved: "{0} saved successfully",
    entityCreated: "{0} created successfully",
    entityDeleted: "{0} deleted successfully",
    entityUpdated: "{0} updated successfully",
    addEntity: "Add {0}",
    deleteEntity: "Delete {0}",
    createEntity: "Create {0}",
    selectEntity: "Select {0}",
    notification: "Notification | Notifications",
    confirm: "Confirm",
    cancel: "Cancel",
    pickFile: "Pick File",
    pickImage: "Pick Image",
    customization: "Customization | Customizations",
    noCustomizationAvailable: "No customization available",
    save: "Save",
    next: "Next",
    selectAtLeastOneProposal: "Select at least one proposal",
    buyerName: "Buyer's Name",
    importingCompany: "Importing Company",
    phone: "Phone",
    email: "Email",
    address: "Address",
    details: "Details",
    taxId: "Tax ID",
    paymentTerms: "Payment Terms",
    deposit: "Deposit",
    balance: "Balance",
    deliveryNeeds: "Delivery Needs",
    status: "Status",
    seller: "Seller",
    nationalizedCost: "Landed Cost",
    discount: "Discount | Discounts",
    lastVersion: "Last Version",
    createdAt: "Created At",
    updatedAt: "Updated At",
    gymName: "Gym Name",
    gymAddress: "Gym Address",
    name: "Name",
    addNew: "Add New",
    edit: "Edit",
    delete: "Delete",
    lastDays: "Last {0} Days",
    allPeriod: "All Period",
    lastWeek: "Last Week",
    lastMonth: "Last Month",
    thisWeek: "This Week",
    thisMonth: "This Month",
    custom: "Custom",
    date: "Date",
    period: "Period",
    totalProposals: "Total Proposals",
    inNegotiation: "In Negotiation",
    approved: "Approved",
    pendingApproval: "Pending Approval",
    new: "New",
    archived: "Archived",
    proposalsPerStatus: "Proposals by Status",
    revenue: "Revenue",
    averageDiscountPerSeller: "Average Discount by Seller",
    proposalsPerSeller: "Proposals by Seller",
    mode: "Mode",
    quantity: "Quantity",
    general: "General",
    bySeller: "By Seller",
    conversionRate: "Conversion Rate",
    topSellingProducts: "Top Selling Products",
    topSellingSubcategories: "Top Selling Subcategories",
    value: "Value",
    pageNotFound: "Page Not Found",
    forgotPassword: "Forgot your password?",
    resetYourPasswordBelow: "Reset your password below",
    resetPasswordInstructions: "Instructions to reset your password will be sent to your email.",
    iRememberMyPassword: "I remember my password",
    login: "Login",
    send: "Send",
    welcomeBack: "Welcome back",
    loginToYourAccountBelow: "Login to your account below",
    rememberMe: "Remember Me",
    password: "Password",
    subcategory: "Subcategory | Subcategories",
    thisActionCannotBeUndone: "This action cannot be undone",
    areYouSure: "Are you sure?",
    changePhoto: "Change Photo",
    removePhoto: "Remove Photo",
    allowedFileFormatAndSize: "Allowed images: {0} and maximum size of {1}.",
    changePassword: "Change Password",
    currentPassword: "Current Password",
    newPassword: "New Password",
    confirmNewPassword: "Confirm New Password",
    profile: "Profile",
    fillAllFields: "Fill all fields",
    passwordsDoNotMatch: "Passwords do not match",
    view: "View",
    number: "Number",
    brlValue: "BRL Value",
    total: "Total",
    order: "Order | Orders",
    dollarToday: "Dollar Today",
    dollarTraded: "Traded Dollar",
    nationalizedPrice: "Landed Price",
    price: "Price",
    code: "Code",
    image: "Image",
    category: "Category | Categories",
    finalPrice: "Final Price",
    remove: "Remove",
    change: "Change",
    supplierCode: "Supplier Code",
    dimensions: "Dimensions",
    dimensionHeight: "Height",
    dimensionWidth: "Width",
    dimensionDepth: "Depth",
    weight: "Gross Weight",
    weightNet: "Net Weight",
    description: "Description",
    englishName: "English Name",
    manualUrl: "Manual URL",
    videoUrl: "Video URL",
    selectCategoryToGenerateCode: "Select a Category to generate the Code",
    selectSubcategoryToGenerateCode: "Select a Subcategory to generate the Code",
    generateCode: "Generate Code",
    filters: "Filters",
    startDate: "Start Date",
    endDate: "End Date",
    version: "Version",
    versions: "Versions",
    nationalizedCostMultiplier: "Landed Cost Multiplier",
    limitInstallmentsByPrice: "Limit Installments by Price",
    item: "Item | Items",
    reset: "Reset",
    valuesBetween: "Values between {0} and {1}",
    valuesAbove: "Values above {0}",
    minimumPrice: "Minimum Price",
    maxInstallments: "Max Installments",
    monthlyInterest: "Monthly Interest",
    minimumDownPayment: "Minimum Down Payment",
    cashPaymentDiscount: "Cash Payment Discount",
    commission: "Commission",
    role: "Role | Roles",
    serverError: "Server Error",
    admin: "Admin",
    cart: "Cart",
    finalize: "Finish",
    unitPrice: "Unit Price",
    actions: "Actions",
    search: "Search",
    cleanFilters: "Clean Filters",
    add: "Add",
    added: "Added",
    generalInformation: "General Information",
    comments: "Comments",
    lastVersionStatus: "Last Version Status",
    lastVersionDate: "Last Version Date",
    approvalDate: "Approval Date",
    source: "Source",
    origin: "Origin",
    link: "Link",
    proposalVersionList: "Proposal Version List",
    viewPublicPage: "View Public Page",
    totalPrice: "Total Price",
    canNotCreateNewVersionWhenThereIsAnApprovedVersion: "Cannot create a new version when there is an approved version",
    copyVersion: "Copy Version",
    interestRate: "Interest Rate",
    totals: "Totals",
    generalDiscount: "General Discount",
    individualDiscount: "Individual Discount",
    payment: "Payment",
    paymentMethod: "Payment Method",
    paymentCash: "Cash Payment",
    paymentInstallment: "Installment Payment",
    shippingCost: "Shipping Cost",
    installment: "Installment | Installments",
    downPaymentAndShippingCost: "Down Payment, Assembly and Shipping Cost",
    automatic: "Automatic",
    manualInterest: "Manual Interest Rate",
    manualValue: "Manual Value",
    installmentValue: "Installment Value",
    downPayment: "Down Payment",
    deliveryDeadline: "Delivery Deadline",
    deliveryDeadlineContent: "This operation may take 90 to 120 days, depending on the selected equipment, customizations made, and geographical location of the delivery address.\n\nExact dates and transparent project tracking are presented at the contract signing meeting.",
    deliveryConditions: "Delivery Conditions",
    deliveryConditionsContent: "It is extremely important that you notify us immediately if there is any unforeseen circumstance that requires the delivery to be postponed, already aligning a new delivery date.\n\nProducts are delivered in wooden and cardboard boxes, and the disposal location of this material (after assembly) must be indicated by the customer.\n\nIn case of discrepancy between the delivery address provided in the contract and the delivery location requested by the customer, it will be necessary to involve our legal and contract departments for a contract amendment.\n\nThe customer is responsible for checking the received goods (according to the invoices) at the time of delivery, noting discrepancies in quantity on the invoice/delivery checklist.\n\nThe check should not be based on the contract but on the invoices delivered. If the equipment is delivered during construction, we will not be responsible for damage to the equipment.",
    qualityWarranty: "Quality Warranty",
    qualityWarrantyContent: "All equipment provided by JW Sport is manufactured following strict quality standards.\n\nWe are committed to offering high-performance and durable products.\n\nIn addition, all equipment comes with a warranty, ensuring customer satisfaction and resolution of any issues.",
    approve: "Approve",
    clickBelowToPrintVersion: "Click below to print the version",
    print: "Print",
    close: "Close",
    proposalApprovedSuccessfully: "Proposal approved successfully",
    compareAndDecide: "Compare and Decide",
    unitAveragePrice: "Unit Average Price",
    others: "Others",
    requiredField: "Required Field",
    structure: "Structure",
    panel: "Panel",
    upholstery: "Upholstery",
    arms: "Arms",
    distributedBy: "Distributed by",
    export: "Export",
    proposalSearchPlaceholder: "Search by customer name",
    copyProposal: "Copy Proposal",
    copy: "Copy",
    initialVersion: "Initial Version",
    goToDashboard: "Go to Dashboard",
    labels: "Labels",
    itemNumber: "Number",
    clientName: "Customer",
    productName: "Product Name",
    colorAndCustomizations: "Color & Customizations",
    qty: "Qtd",
    proposalValidity: "Proposal validity",
    proposalValidityText: "This proposal is valid for 5 days",
    minimumMarkup: "Minimum Markup",
    entityNotFound: "{0} not found",
    supplier: "Supplier | Suppliers",
    document: "Document",
    text: "Text",
    textEnglish: "Text in English",
    textChinese: "Text in Chinese",
    option: "Option",
    options: "Options",
    apply: "Apply",
    nameIsInUsed: "Name is in use",
    applyToAllOfSameCategory: "Apply to all of the same category",
    documentsAndLabels: "Documents and Labels",
    assembly: "Assembly",
    itemsTotalValue: "Total value of items",
    otherCosts: "Other costs",
    alphaCode: "Alpha code",
    unknown: "Unknown",
    inProduction: "In Production Queue",
    inCutAndFold: "In Cut and Fold",
    painting: "Painting",
    drying: "Drying",
    preAssembly: "In Pre-Assembly",
    inQualityControl: "In Quality Control",
    readyToShip: "Ready to Ship",
    seaRoute: "On a Sea Route",
    destinationPort: "At Destination Port",
    awaitingCargoRelease: "Awaiting Cargo Release",
    roadRoute: "On a Road Route",
    storedInDC: "Stored In DC",
    assembling: "Assembling",
    delivered: "Delivered",
    approvedProposalsPerSeller: "Approved Proposals by Seller",
    table: "Table",
    display: "Display",
    chart: "Chart",
    position: "Position",
    sells: "Sells",
    proposalsPerClient: "Orders by Customer",
    approvedProposalQuantity: "Approved Proposal Quantity",
    approvedProposalValue: "Approved Proposal Value",
    lastPurchase: "Last Purchase",
    viewEntity: "View {0}",
    maritimeTrackingCode: "Maritime Tracking Code",
    roadTrackingCode: "Road Tracking Code",
    estimatedDelivery: "Estimated Delivery",
    containers: "Containers",
    container: "Container",
    group: "Group",
    groups: "Groups",
    taxID: "TAXID",
    invoice: "Invoice",
    predefinedFields: "Predefined Fields",
    shippingTracking: "Shipping Tracking",
    productsQuantity: "Products Quantity",
    paymentConditions: "Payment conditions",
    contractSigning: "At the signing of the contract",
    multiplier: "Multiplier",
    assemblyDate: "Assembly Date",
    assemblyStatus: "Assembly Status",
    assemblyObservations: "Assembly Observations",
    inProgress: "In Progress",
    done: "Done",
    photos: "Photos",
    confirmPassword: "Confirm Password",
    equipment: "Equipment",
    equipments: "Equipments",
    assemblyChecklist: "Assembly Checklist",
    assembler: "Assembler",
    customer: "Customer",
    registers: "Registers",
    count: "Count",
    range: "Range",
    title: "Title",
    tickets: "Tickets",
    ticket: "Ticket",
    type: "Type",
    malfunction: "Malfunction",
    low: "Low",
    medium: "Medium",
    high: "High",
    openTicket: "Open Ticket",
    openData: "Open Data",
    closeData: "Close Data",
    closeDescription: "Close Description",
    images: "Images",
    saveAndFinalize: "Save and Finalize",
    responsible: "Responsible",
    create: "Create",
    fairing: 'Fairing',
    digitalCounter: 'Digital counter',
    weightTower: 'Weight tower',
    steelCable: 'Steel cable',
    bearingsAndShafts: 'Bearings and shafts',
    stickers: 'Stickers',
    ledPanel: 'LED panel',
    touchPanel: 'Touch panel',
    oiling: 'Oiling',
    pinsAndAdjustments: 'Pins and adjustments',
    belt: 'Belt',
    crankset: 'Crankset',
    pulley: 'Pulley',
    nature: 'Nature',
    preventive: 'Preventive',
    manufacturingDefect: 'Manufacturing Defect',
    misuse: 'Misuse',
    earlyWear: 'Early wear',
    saveAndCloseTab: 'Save and Close Tab',
    defaultWeightStack: 'Default Weight Stack',
    weightStackDescription: 'Weight Stack Description',
    metaTitle: 'Meta Title',
    metaDescription: 'Meta Description',
    hideArchived: 'Hide Archived',
    commissions: 'Commissions',
    reports: 'Reports',
    settings: 'Settings',
    commissionSetting: 'Commission Setting',
    discountLimit: 'Discount Limit',
    percentage: 'Percentage',
    commissionReport: 'Commission Report',
    observation: 'Observation',
    generate: 'Generate',
    thereAreCustomItemsWithEmptyObservation: 'There are custom items with empty observation',
    noName: 'No Name',
    default: 'Default',
    totalSells: 'Total Sells',
    totalCommissions: 'Total Commissions',
    quarterBonusGoal: 'Trimestal Bonus Goal',
    quarterBonusValue: 'Trimestal Bonus Value',
    year: 'Year',
    month: 'Month',
    goal: 'Goal',
    commissionGenerated: 'Commission Generated',
    quarterBonus: 'Trimestal Bonus',
    totalToReceive: 'Total to Receive',
    countdown: 'Countdown',
    commissionsReceived: 'Commissions Received',
    extraImages: 'Extra Images',
    dateSugestion: 'Date Suggestion',
    maxDeliveryDate: 'Max Delivery Time',
    daysSinceOrder: 'Days since order',
    viewManual: 'View manual'
}
