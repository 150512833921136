import Vue from "vue";

import Api from "@/lib/Api";
import * as utils from "@/lib/Utils";
import * as calculations from "@/lib/Calculations";
import colors from 'vuetify/lib/util/colors'

import { format, formatISO, formatRelative } from "date-fns";
import { i18n } from "./i18n";
import { appConfig } from "./appConfig";

Vue.prototype.$toast = utils.emitToastr;
Vue.prototype.$api = Api;
Vue.prototype.$utils = utils;
Vue.prototype.$calculations = calculations;

Vue.prototype.$navigateTo = function(route, options){
    const isHttp = route.startsWith('http') || route.startsWith('https')

    if (isHttp) {
        window.open(route, options.target || '_self');
        return
    }

    const resolvedRoute = this.$router.resolve(route)

    if (options.target === '_blank') {
        window.open(resolvedRoute.href, '_blank');
        return
    }

    this.$router.push(route)
}


Vue.prototype.$currency = {
    mainSymbol(){
        if (appConfig.currency === 'usd') {
            return '$'
        }

        return 'R$'
    },
    main(value){
        if (appConfig.currency === 'usd') {
            return this.usd(value)
        }

        return this.brl(value)
    },
    usd(value){
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    },
    brl(value){
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    }
}

Vue.prototype.$number = {
    toFloat(value){
        if (value) value = Number(value);
        return Number(value.toFixed(2))
    },
    zeroFill(value, width = 4){
        return `${'0'.repeat(width - 1)}${value}`.slice(-width)
    }
}

Vue.prototype.$date = {
    defaultFormat(value, withTime = true){
        let pattern = "dd/MM/yyyy";

        if (withTime) {
            pattern += " HH:mm"
        }

        return format(new Date(value), pattern);
    },
    js(value){
        return new Date(value)
    },
    iso(value){
        return formatISO(value)
    },
    format(value, pattern){
        let date = new Date(value)

        return format(date, pattern);
    },
    relative(value){
        return formatRelative(new Date(value), new Date());
    }

}

Vue.prototype.$color = {
    findByIndex(index = 0){        
        const options = Object.keys(colors).map(key => colors[key].base)

        return options[index % options.length]
    }
}

Vue.prototype.$rules = {
    required: (message = i18n.t("requiredField")) => {
        return v => !!v || message;
    },
    minLength: (min, message = i18n.t("invalidField")) => {
        return v => {
            if (!v) {
                return true
            }

            return v.length >= min || message;
        } 
    },
    regex: (pattern, message = i18n.t("invalidField")) => {
        return v => {
            if (!v) {
                return true
            }

            return pattern.test(v) || message;
        }
    },
    sameAs: (field, message = i18n.t("invalidField")) => {
        return v => {
            if (!v) {
                return true
            }

            return v === field || message;
        } 
    }
}
