export default {
    proposalGenerator: "Gerador de Propostas",
    myProfile: "Meu Perfil",
    proposal: "Proposta",
    proposals: "Propostas",
    proposalName: "Nome da proposta",
    client: "Cliente | Clientes",
    product: "Produto",
    products: "Produtos",
    user: "Usuário | Usuários",
    setting: "Configuração | Configurações",
    logout: "Sair",
    markup: "Markup | Markups",
    noEntity: "Sem {0}",
    newEntity: "Novo(a) {0}",
    entityList: "Lista de {0}",
    entityDetails: "Detalhes de {0}",
    entitySaved: "{0} salvo(a) com sucesso",
    entityCreated: "{0} criado(a) com sucesso",
    entityDeleted: "{0} deletado(a) com sucesso",
    entityUpdated: "{0} atualizado(a) com sucesso",
    addEntity: "Adicionar {0}",
    deleteEntity: "Deletar {0}",
    createEntity: "Criar {0}",
    selectEntity: "Selecione {0}",
    notification: "Notificação | Notificações",
    confirm: "Confirmar",
    cancel: "Cancelar",
    pickFile: "Escolher Arquivo",
    pickImage: "Escolher Imagem",
    customization: "Personalização | Personalizações",
    noCustomizationAvailable: "Nenhuma personalização disponível",
    save: "Salvar",
    next: "Próximo",
    selectAtLeastOneProposal: "Selecione pelo menos uma proposta",
    buyerName: "Nome do Comprador",
    importingCompany: "Empresa Importadora",
    phone: "Telefone",
    email: "Email",
    address: "Endereço",
    details: "Detalhes",
    taxId: "Tax ID",
    paymentTerms: "Termos de Pagamento",
    deposit: "Depósito",
    balance: "Saldo",
    deliveryNeeds: "Necessidades de Entrega",
    status: "Status",
    seller: "Vendedor",
    nationalizedCost: "Custo Nacionalizado",
    discount: "Desconto | Descontos",
    lastVersion: "Última Versão",
    createdAt: "Criado em",
    updatedAt: "Atualizado em",
    gymName: "Nome da Academia",
    gymAddress: "Endereço da Academia",
    name: "Nome",
    addNew: "Adicionar Novo",
    edit: "Editar",
    delete: "Deletar",
    lastDays: "Últimos {0} Dias",
    allPeriod: "Todo o Período",
    lastWeek: "Última Semana",
    lastMonth: "Último Mês",
    thisWeek: "Essa Semana",
    thisMonth: "Esse Mês",
    custom: "Personalizado",
    date: "Data",
    period: "Período",
    totalProposals: "Total de Propostas",
    inNegotiation: "Em Negociação",
    approved: "Aprovado",
    pendingApproval: "Em Aprovação",
    new: "Novo",
    archived: "Arquivado",
    proposalsPerStatus: "Propostas por Status",
    revenue: "Receita",
    averageDiscountPerSeller: "Desconto Médio por Vendedor",
    proposalsPerSeller: "Propostas por Vendedor",
    mode: "Modo",
    quantity: "Quantidade",
    general: "Geral",
    bySeller: "Por Vendedor",
    conversionRate: "Taxa de Conversão",
    topSellingProducts: "Produtos Mais Vendidos",
    topSellingSubcategories: "Subcategorias Mais Vendidas",
    value: "Valor",
    pageNotFound: "Página não encontrada",
    forgotPassword: "Esqueceu sua senha?",
    resetYourPasswordBelow: "Redefina sua senha abaixo",
    resetPasswordInstructions: "Instruções para redefinir sua senha serão enviadas para o seu email.",
    iRememberMyPassword: "Lembrei minha senha",
    login: "Login",
    send: "Enviar",
    welcomeBack: "Bem-vindo de volta",
    loginToYourAccountBelow: "Faça login em sua conta abaixo",
    rememberMe: "Lembrar de mim",
    password: "Senha",
    subcategory: "Subcategoria | Subcategorias",
    thisActionCannotBeUndone: "Essa ação não pode ser desfeita",
    areYouSure: "Você tem certeza?",
    changePhoto: "Alterar Foto",
    removePhoto: "Remover Foto",
    allowedFileFormatAndSize: "Imagens permitidas: {0} e com tamanho máximo de {1}.",
    changePassword: "Alterar Senha",
    currentPassword: "Senha Atual",
    newPassword: "Nova Senha",
    confirmNewPassword: "Confirme a nova senha",
    profile: "Perfil",
    fillAllFields: "Preencha todos os campos",
    passwordsDoNotMatch: "As senhas não coincidem",
    view: "Ver",
    number: "Número",
    brlValue: "Valor em BRL",
    total: "Total",
    order: "Pedido | Pedidos",
    dollarToday: "Dólar Hoje",
    dollarTraded: "Dólar Negociado",
    nationalizedPrice: "Preço Nacionalizado",
    price: "Preço",
    code: "Código",
    image: "Imagem",
    category: "Categoria | Categorias",
    finalPrice: "Preço Final",
    remove: "Remover",
    change: "Alterar",
    supplierCode: "Código do Fornecedor",
    dimensions: "Dimensões",
    dimensionHeight: "Altura",
    dimensionWidth: "Largura",
    dimensionDepth: "Profundidade",
    weight: "Peso Bruto",
    weightNet: "Peso Líquido",
    description: "Descrição",
    englishName: "Nome em Inglês",
    manualUrl: "URL do Manual",
    videoUrl: "URL do Vídeo",
    selectCategoryToGenerateCode: "Selecione uma Categoria para gerar o Código",
    selectSubcategoryToGenerateCode: "Selecione uma Subcategoria para gerar o Código",
    generateCode: "Gerar Código",
    filters: "Filtros",
    startDate: "Data Inicial",
    endDate: "Data Final",
    version: "Versão",
    versions: "Versões",
    nationalizedCostMultiplier: "Multiplicador de Custo Nacionalizado",
    limitInstallmentsByPrice: "Limite de parcelamentos por preço",
    item: "Item | Itens",
    reset: "Redefinir",
    valuesBetween: "Valores entre {0} e {1}",
    valuesAbove: "Valores acima de {0}",
    minimumPrice: "Preço Mínimo",
    maxInstallments: "Máximo de Parcelas",
    monthlyInterest: "Juros Mensal",
    minimumDownPayment: "Entrada Mínima",
    cashPaymentDiscount: "Desconto para Pagamento à Vista",
    commission: "Comissão",
    role: "Função | Funções",
    serverError: "Erro no servidor",
    admin: "Administrador",
    cart: "Carrinho",
    finalize: "Finalizar",
    unitPrice: "Preço Unitário",
    actions: "Ações",
    search: "Buscar",
    cleanFilters: "Limpar Filtros",
    add: "Adicionar",
    added: "Adicionado",
    generalInformation: "Informações Gerais",
    comments: "Comentários",
    lastVersionStatus: "Status da Última Versão",
    lastVersionDate: "Data da Última Versão",
    approvalDate: "Data da Aprovação",
    source: "Source",
    origin: "Origin",
    link: "Link",
    proposalVersionList: "Lista de Versões da Proposta",
    viewPublicPage: "Ver Página Pública",
    totalPrice: "Preço Total",
    canNotCreateNewVersionWhenThereIsAnApprovedVersion: "Não é possível criar uma nova versão quando há uma versão aprovada",
    copyVersion: "Copiar Versão",
    interestRate: "Taxa de juros",
    totals: "Totais",
    generalDiscount: "Desconto Geral",
    individualDiscount: "Desconto Individual",
    payment: "Pagamento",
    paymentMethod: "Método de Pagamento",
    paymentCash: "Pagamento à Vista",
    paymentInstallment: "Pagamento Parcelado",
    shippingCost: "Frete",
    installment: "Parcela | Parcelas",
    downPaymentAndShippingCostAssembly: "Entrada, Montagem e Frete",
    automatic: "Automático",
    manualInterest: "Taxa de Juros Manual",
    manualValue: "Valor Manual",
    installmentValue: "Valor da Parcela",
    downPayment: "Entrada",
    deliveryDeadline: "Prazo de Entrega",
    deliveryDeadlineContent: "Esta operação pode levar de 90 a 120 dias, a depender dos equipamentos selecionados, customizações realizadas e localização geográfica do endereço de entrega.\n\nDatas exatas e um acompanhamento transparente do projeto são apresentadas na reunião de assinatura de contrato.",
    deliveryConditions: "Condições de Entrega",
    deliveryConditionsContent: "É de suma importância que nos avisem imediatamente caso haja algum imprevisto no qual a entrega tenha que ser adiada, já deixando alinhado nova data de entrega.\n\nOs produtos são entregues em caixas de madeira e papelão, o local de descarte deste material (após montagem) deverá ser indicado pelo cliente.\n\nHavendo divergência entre o endereço de entrega informado no contrato e o local de entrega solicitado pelo cliente, será necessário envolver nosso setor jurídico e setor de contratos para aditivo de contrato.\n\nO cliente é responsável por conferir a mercadoria recebida (de acordo com as notas fiscais) no ato da entrega, fazendo o apontamento de divergências de quantidade na nota fiscal/checklist de entrega.\n\nA conferência não deve ser feita com base no contrato e sim nas notas fiscais entregues. Caso os equipamentos sejam entregues durante a obra, não nos responsabilizaremos por dano aos equipamentos. de Entrega",
    qualityWarranty: "Garantia de Qualidade",
    qualityWarrantyContent: "Todos os equipamentos fornecidos pela EvoGear são fabricados seguindo padrões rigorosos de qualidade.\n\nEstamos comprometidos em oferecer produtos de alta performance e durabilidade.\n\nAlém disso, todos os equipamentos vêm acompanhados de uma garantia, assegurando a satisfação do cliente e a resolução de eventuais problemas.",
    approve: "Aprovar",
    clickBelowToPrintVersion: "Clique abaixo para imprimir a versão",
    print: "Imprimir",
    close: "Fechar",
    proposalApprovedSuccessfully: "Proposta aprovada com sucesso",
    compareAndDecide: "Compare e Decida",
    unitAveragePrice: "Preço Médio Unitário",
    others: "Outros",
    requiredField: "Campo Obrigatório",
    structure: "Estrutura",
    panel: "Painel",
    upholstery: "Estofamento",
    arms: "Braços",
    distributedBy: "Distribuído por",
    export: "Exportar",
    proposalSearchPlaceholder: "Busque pelo nome do cliente",
    copyProposal: "Copiar Proposta",
    copy: "Copia",
    initialVersion: "Versão Inicial",
    goToDashboard: "Ir para o Dashboard",
    labels: "Tickets",
    itemNumber: "Nº do Item",
    clientName: "Nome do Cliente",
    productName: "Nome do Produto",
    colorAndCustomizations: "Cor e Personalizações",
    qty: "Qtde",
    proposalValidity: "Validade da proposta",
    proposalValidityText: "Essa proposta tem validade de 5 dias",
    minimumMarkup: "Markup Mínimo",
    entityNotFound: "{0} não encontrado(a)",
    supplier: "Fornecedor | Fornecedores",
    document: "Documento",
    text: "Texto",
    textEnglish: "Texto em Inglês",
    textChinese: "Texto em Chinês",
    option: "Opção",
    options: "Opções",
    apply: "Aplicar",
    nameIsInUsed: "Nome já está em uso",
    applyToAllOfSameCategory: "Aplicar a todos da mesma categoria",
    documentsAndLabels: "Documentos e Etiquetas",
    assembly: "Montagem",
    itemsTotalValue: "Valor total dos equipamentos",
    otherCosts: "Custos adicionais",
    alphaCode: "Código alfa",
    unknown: "Desconhecido",
    inProduction: "Em Fila de Produção",
    inCutAndFold: "Em Corte e Dobra",
    painting: "Pintura",
    drying: "Em Secagem",
    preAssembly: "Em Pré-Montagem",
    inQualityControl: "Em Controle de Qualidade",
    readyToShip: "Fabricação Concluída",
    seaRoute: "Em Rota Marítima",
    destinationPort: "No Porto de Destino",
    awaitingCargoRelease: "Aguardando Liberação da Carga",
    roadRoute: "Em Rota Rodoviária",
    storedInDC: "Armazenado no CD",
    assembling: "Em Montagem",
    delivered: "Entregue",
    approvedProposalsPerSeller: "Propostas aprovadas por vendedor",
    table: "Tabela",
    display: "Exibição",
    chart: "Gráfico",
    position: "Posição",
    sells: "Vendas",
    proposalsPerClient: "Pedidos por Cliente",
    approvedProposalQuantity: "Qtd Propostas Aprovadas",
    approvedProposalValue: "Valor de Propostas Aprovadas",
    lastPurchase: "Última Compra",
    viewEntity: "Ver {0}",
    maritimeTrackingCode: "Código de Rastreio Marítimo",
    roadTrackingCode: "Código de Rastreio Rodoviário",
    estimatedDelivery: "Previsão de Entrega",
    containers: "Containers",
    container: "Container",
    group: "Grupo",
    groups: "Grupos",
    taxID: "CNPJ",
    invoice: "Invoice",
    predefinedFields: "Campos Predefinidos",
    shippingTracking: "Rastreio de Entrega",
    productsQuantity: "Quantidade de Produtos",
    paymentConditions: "Forma de pagamento",
    contractSigning: "Na assinatura do contrato",
    multiplier: "Multiplicador",
    assemblyDate: "Data de Montagem",
    assemblyStatus: "Status de Montagem",
    assemblyObservations: "Observações de Montagem",
    inProgress: "Em Progresso",
    done: "Finalizado",
    photos: "Fotos",
    confirmPassword: "Confirmar Senha",
    equipment: "Equipamento",
    equipments: "Equipamentos",
    assemblyChecklist: "Checklist de montagem",
    assembler: "Montador",
    customer: "Cliente",
    registers: "Registros",
    count: "Contagem",
    range: "Intervalo",
    title: "Título",
    tickets: "Chamados",
    ticket: "Chamado",
    type: "Tipo",
    malfunction: "Mau funcionamento",
    low: "Baixo",
    medium: "Médio",
    high: "Alto",
    openTicket: "Abrir Chamado",
    openData: 'Dados de Abertura',
    closeData: 'Dados de Fechamento',
    closeDescription: 'Descrição de Fechamento',
    images: 'Imagens',
    saveAndFinalize: 'Salvar e Finalizar',
    responsible: 'Responsável',
    create: 'Criar',
    fairing: 'Carenagem',
    digitalCounter: 'Contador digital',
    weightTower: 'Torre de peso',
    steelCable: 'Cabo de aço',
    bearingsAndShafts: 'Rolamentos e eixos',
    stickers: 'Adesivos',
    ledPanel: 'Painel LED',
    touchPanel: 'Painel touch',
    oiling: 'Lubrificação',
    pinsAndAdjustments: 'Pinos e ajustes',
    belt: 'Correia',
    crankset: 'Pedivela',
    pulley: 'Polia',
    nature: 'Natureza',
    preventive: 'Preventiva',
    manufacturingDefect: 'Defeito de fabricação',
    misuse: 'Mau uso',
    earlyWear: 'Desgaste precoce',
    saveAndCloseTab: 'Salvar e Fechar Aba',
    defaultWeightStack: 'Weight Stack Padrão (em kg)',
    weightStackDescription: 'Código e Quantidade de Weight Stack',
    metaTitle: 'Meta Title',
    metaDescription: 'Meta Description',
    hideArchived: 'Ocultar Arquivados',
    commissions: 'Comissões',
    reports: 'Relatórios',
    settings: 'Configurações',
    commissionSetting: 'Configuração de Comissão',
    discountLimit: 'Limite de Desconto',
    percentage: 'Porcentagem',
    commissionReport: 'Relatório de Comissão',
    observation: 'Observação',
    generate: 'Gerar',
    thereAreCustomItemsWithEmptyObservation: 'Existem itens personalizados com observação vazia',
    noName: 'Sem Nome',
    default: 'Padrão',
    totalSells: 'Total de Vendas',
    totalCommissions: 'Total de Comissões',
    quarterBonusGoal: 'Bônus Trimestral Meta',
    quarterBonusValue: 'Bônus Trimestral Valor',
    year: 'Ano',
    month: 'Mês',
    goal: 'Meta',
    commissionGenerated: 'Comissão Gerada',
    quarterBonus: 'Bônus Trimestral',
    totalToReceive: 'Total a Receber',
    countdown: 'Contagem Regressiva',
    commissionsReceived: 'Comissões Recebidas',
    extraImages: 'Imagens Extras',
    passwordRequirements: 'A senha deve conter pelo menos 8 caracteres, um número e um caractere especial',
    dateSugestion: 'Sugestão de Data',
    maxDeliveryDate: 'Prazo Máximo de Entrega',
    daysSinceOrder: 'Dias desde o Pedido',
    viewManual: 'Ver manual'
}
