<template>
    <div>
        <slot :pick="pick">
            <v-btn
                v-if="!file"
                @click="pick"
                color="primary"
            >
                <v-icon left>
                    mdi-file
                </v-icon>

                {{ $t('pickFile') }}
            </v-btn>

            <v-btn
                v-else
                @click="pick"
                color="primary"
            >
                <v-icon left>
                    mdi-file
                </v-icon>

                <v-chip small>
                    <div class="text-truncate w-full">
                        {{ file.name.length > 20 ? file.name.substring(0, 20) + '...' : file.name }}
                    </div>
                </v-chip>
            </v-btn>
        </slot>
    </div>
</template>

<script>
export default {
    props: {
        file: {
            type: File,
            default: null
        },
        accept: {
            type: String,
            default: undefined
        }
    },
    computed: {
        fileModel: {
            get() {
                return this.file
            },
            set(file) {
                this.$emit('update:file', file)
            }
        },
    },
    methods: {
        async pick() {
            const input = document.createElement('input')

            input.type = 'file'
            input.accept = this.accept

            input.onchange = async (e) => {
                const file = e.target.files[0]

                this.fileModel = file
            }
            
            input.click()
        }
    }
}
</script>